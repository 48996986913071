import { LoginReq, OtpPayload, ValidateOtp, WhatsappPreference } from './models/Login';
import { isEmpty } from 'lodash';
import {
    downloadFileApi,
    fetchBanks,
    fetchFundingCalculatorValues,
    fetchUserInfo,
    postLoginInfo,
    saveCapitalDetailsOnStartApplication,
    postSignupInfo,
    sendOtp,
    fetchDrawdownOrders,
    fetchInvestorPortfolioGraphs,
    fetchOffers,
    fetchAllDeals,
    fetchUnderwritingMapping,
    fetchTdsFilingReportDownload,
    fetchTotData,
    fetchInvestmentDetails,
    fetchCompanyOverview,
    fetchTotalAccepted,
    fetchTotalAcceptedForDebt,
    fetchTotalSanctioned,
    fetchTotalDisbursed,
    fetchMonthlyDisbursments,
    fetchFeeAndTenure,
    fetchPrincipalOutstanding,
    fetchAverageRecurGrade,
    fetchRepaymentHistoryGraph,
    fetchDpdView,
    fetchInvesteeDpdData,
    fetchTotalAcceptedDrilldown,
    validateOtp,
    fetchPGConnections,
    registerRazorPG,
    editRazorPG,
    whatsappPreference,
    fetchRcmData,
    fetchAllDealsLinkedToATot,
    callApiToEsignDocsAndMakeCompanyActive,
    callApiForLegalitySign,
    callApiToGetSidebarConfigTab,
    fetchSignedUrlToUpload,
    saveMetaDataInDocService,
    updateKycMetaData,
    deleteDoc,
    downloadFilePresigned,
    fetchKycDocuments,
    fetchAddOnStatus,
    fetchSanctionDrilldown,
    fetchDisbursmentDrilldown,
    fetchMonthlyOnboardingStatus,
    fethcIntegrations,
    callApiToSubmitAddOnLimitData,
    fetchFinboxSessionUrl,
    callApiToUpdateMetaData,
    fetchUploadSignedUrl,
    callApiToDeleteFile,
    callApiToUpdateMetaDataV3,
    callApiToFetchMonthlyViewData,
    callApiToIntegrateSource,
    fetchCompanyCollectionsHistory,
    fetchCollectionDashboardData,
    fetchTdsClaimData,
    fetchTdsClaimDataAllTime,
    fetchTdsDepositdates,
    fetchCsvForTdsDeposit,
    updateTdsClaimData,
    fetchCoborrowerDetails,
    callInviteCoborrower,
    callUpdateCoborrowerDetails,
    callApiToAcceptAddOnTot,
    fetchSecondaryRcmData,
    callApiToSaveContactUsData,
    callApiToFetchAllNach,
    callApiToUpdateNachSignatory,
    callApiToSetupNach,
    callApiToUploadPhysicalNach,
    callApiToSelectNachType,
    downloadFileBulk,
    callApiToFetchDeals,
    callApiToUpdateInvestorDealStatus,
    callApiToUpdateDeal,
    callApiToSaveDealFeedback,
    callApiToFetchDealConversation,
    callApiToSaveMessageOrDraftForDealConversation,
    callApiToDeleteFileFromDocService,
    fetchOnboardingData,
    fetchMonthlyViewData,
    callApiToUpdateIndicativeTerms,
    callAptToFetchCompanyMatchPercentage,
    callApiToUpdateLimitOverview,
    callApiToGetDocVerificationDeals,
    fetchcibilVerificationStatus,
    sendCibilVerificationRequest,
    sendCibilVerificationStatus,
    verifyGstin,
    callAptToFetchCapitalAvailableData,
    callAptToFetchCollectionMode,
    callApiToGetWidgetData,
    callApiToFetchPortfolioDeals,
    callAptToFetchRepaymentSchedule,
    FetchWaitlistedData,
    callApiToFetchForecastData,
    callApiToUpdateDocumentationStatus,
    fetchTdsClaimsForInvestor,
    postTdsClaimForInvestor,
    callApiToUpdateODFDdata,
    callApiToUpdateActiveLoanOrDebt,
    callApiToUpdateLoanCheckInNeedInfoOnboarding,
    regenerateCounterSigningLink,
    callApiToGetCounterSignUser,
    getDealManagerData,
    updatePecDetails,
    getCounterSignLinkFromEncryptedDealId,
    fetchTotalAcceptedDrilldownForDebt,
    fetchNachStatusOldLines,
    fetchDebtTotData,
    fetchRecurScaleOffers,
    fetchKycAppData,
    fetchNoOfChartsUnlocked,
    fetchARRGrowthPercentile,
    fetchInsightsGrowth,
    editIpaDocInDealOffer,
    fetchCsvForZeusUser,
    callRegisterClaim,
    fetchTdsClaim,
    fetchInvestorInfo,
    callUpdateClaim,
    fetchDscStatus,
    getCustomerQueries,
    updateGlobalNeedInfo,
    fetchTotalSanctionedForDebt,
    fetchSanctionDrilldownForDebt,
    fetchMonthlyDisbursmentsForDebt,
    getScheduledInvestorLedger,
    getCpForTotId,
    updateCpDataForCompany,
    fetchDrawdowns,
    registerBalanceConfirmation,
    fetchBalanceConfirmationData,
    updateBalanceConfirmationApi,
    callApiToGetPendingDrawdowns,
    registerSupportQueryTicket,
    getSupportQueryApi,
    getAllTdsPendingForOrders,
    fetchApplicationStrength,
    callPartiallySubmitApplication,
    fetchInvestorWiseCollectionData,
    callParseDocument,
    fetchParsedData,
    submitTotalRepaymentProof,
    fetchRecordedPaymentAmount,
    fetchMobileUploadingSteps,
    sentNotificationViaMailForMobile,
    reapplyForOnboarding,
    fetchBase64UrlFromMultipartType,
    fetchInvestorOpsMapping,
    getRecordedPayments,
    updateRecordPaymentStatus,
    fundingCalculation,
    updateGSTAddress,
    fetchDealManager,
    addDealManager,
    fetchConfigForAdditionalDocs,
    callApiToGetDoesCompanyContainNotMetCompliance,
    getCompaniesLegalNamesListService,
    isNameMatchWithPanFailed,
    updateNameFetchedFromPan,
    postAcceptTotAndESign,
    postResendSignReq,
    fetchLenderPlatfromFeePercent,
    getScheduledInvestorLedgerExcel,
} from '../../../common/ApiContainer';
import { CerberusResponse, SignupPayload } from './models/Signup';

import { captureMessagesViaErrorLogger } from '../../../util/errorLogger';
import { CapitalDetailsReq } from './models/CapitalDetails';
import { showNotification } from '../../../util/utility';
import { monthYear } from './models/DownloadFile';
import {
    API_STATUS,
    CERBERUS_STATUS_CODES,
    GENERIC_ERROR,
    SHOW_NOTIFICATION_STATUS,
} from '../../../enums/appEnums';
import { GlobalNeedInfoProps, TdsClaimsInterface, tdsDatesProps } from './models/Tds';
import { JAVA_API_STATUS, LOAN_TYPE } from '../../../constants/consts';
import { debtPayload } from '../pages/GetStartedPageNew/components/TotSelectionDrawer/TotSelectionDrawer';
import { updateKycDetailsToLatest } from '../InvesteeActions';
import { IPA_ACCEPTED } from '../../Investor/pages/NewDeals/consts/consts';
import {
    RegisterClaimDataPayload,
    UpdateClaimPayload,
} from '../../../common/Types/ApiContainerTypes';
import { ConditionPrecedent } from './models/ConditionPrecedent';

const INVALID_ID = 'Invalid Id';
const INCOMPLETE_DATA = 'Incomplete data';
const STATUS_CODE = {
    SUCCESS: 200,
};
const INTERNAL_RESPONSE_CODE = {
    SUCCESS: 20,
};
const onFailureShow = (message: string) => {
    const showMessage = message ?? GENERIC_ERROR;
    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, showMessage);
};

export const loginHelper = async (data: LoginReq): Promise<any> => {
    //loginUser  in app actions // whole logic should be here.
    try {
        if (isEmpty(data)) return null;
        const response = await postLoginInfo(data);
        // right use case here
        return response?.data;
    } catch (err: any) {
        //@TODO make errors here not in component error should not be 404 from backend
        if (err?.response?.data) return err?.response?.data;
        captureMessagesViaErrorLogger(err);
    }
};

export const getUserInfo = async (userId: string): Promise<any> => {
    try {
        if (!userId || typeof userId !== 'string') throw new Error('Please pass a valid user id');
        const response = await fetchUserInfo(userId);
        // right use case here
        if (response.status === STATUS_CODE.SUCCESS) return response.data;
        return response;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getBanks = async ({
    organizationId,
    organizationType: organization_type,
}: {
    organizationId: string;
    organizationType: string;
}): Promise<any> => {
    try {
        if (!organizationId || typeof organizationId !== 'string' || !organization_type)
            throw new Error('invalid id');
        const response = await fetchBanks({ organization_type }, organizationId);
        // right use case here
        if (response.status === STATUS_CODE.SUCCESS) return response.data;
        return response;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchOnboardingDataHelper = async ({
    investeeOnboardingId,
    onSuccess,
    onError,
}: {
    investeeOnboardingId: string;
    onSuccess: Function;
    onError: Function;
}): Promise<any> => {
    try {
        const response = await fetchOnboardingData(investeeOnboardingId);
        if (response.status === 200) {
            const data = response.data?.data?.investeeOnboardingData;
            onSuccess && onSuccess(data);
        }
    } catch (err) {
        onError && onError(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchMonthlyViewDataHelper = async ({
    investeeOrgId,
    onSuccess,
    onError,
}: {
    investeeOrgId: string;
    onSuccess: Function;
    onError: Function;
}): Promise<any> => {
    try {
        const response = await fetchMonthlyViewData(investeeOrgId);
        if (response.status === 200) {
            const data = response.data?.data;
            onSuccess && onSuccess(data);
        }
    } catch (err) {
        onError && onError(err);
        captureMessagesViaErrorLogger(err);
    }
};
/**
 *
 * @description saving capital required details while OP1
 */
export const saveCapitalDetailsHelper = async ({
    investeeOrgId,
    data,
    onSuccess,
}: {
    investeeOrgId: string;
    data: CapitalDetailsReq;
    onSuccess: Function;
}): Promise<any> => {
    try {
        if (!investeeOrgId) throw new Error(INVALID_ID);
        if (isEmpty(data) || !(data.capital_required_amount && data.capital_required_date))
            throw new Error(INCOMPLETE_DATA);
        const res = await saveCapitalDetailsOnStartApplication(investeeOrgId, data);
        const resData = res.data;
        if (res.status === STATUS_CODE.SUCCESS) onSuccess(resData);
        else onFailureShow(resData?.data?.message);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getFundingCalculatorValues = async ({
    investeeOnboardingId,
    onSuccess,
}: {
    investeeOnboardingId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        if (!investeeOnboardingId) throw new Error(INVALID_ID);
        const res = await fetchFundingCalculatorValues(investeeOnboardingId);
        const resData = res.data;
        if (res.status === STATUS_CODE.SUCCESS) onSuccess(resData);
        else onFailureShow(resData?.data?.message);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const downloadFileHelper = async ({
    url,
    bucket,
    investeeOrgId,
}: {
    url: string;
    bucket?: string;
    investeeOrgId?: string;
}): Promise<any> => {
    try {
        if (!url) throw new Error('Please pass a valid url');
        const response = await downloadFileApi({ url, bucket, investeeOrgId });
        if (response.status === STATUS_CODE.SUCCESS) return response.data;
        return response;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const fetchPdfBlobUrlFromMultipartType = async (
    url: string,
    axiosResponseType: string,
): Promise<any> => {
    try {
        const response = await fetchBase64UrlFromMultipartType(url, axiosResponseType);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        let blobURL = URL.createObjectURL(blob);
        return blobURL;
    } catch (error) {
        captureMessagesViaErrorLogger(error);
    }
    return null;
};
export const signupHelper = async (
    data: SignupPayload,
): Promise<CerberusResponse | string | null> => {
    try {
        if (isEmpty(data)) return null;
        const response = await postSignupInfo(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return response?.data as CerberusResponse;
        } else return (response?.data as CerberusResponse)?.responseData?.responseMessage;
    } catch (error) {
        captureMessagesViaErrorLogger(error);
    }
    return null;
};
export const sendOtpHelper = async (data: OtpPayload): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await sendOtp(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return response?.data;
        } else return response?.data?.responseData?.responseMessage;
    } catch (error) {
        captureMessagesViaErrorLogger(error);
    }
};
export const validateOtpHelper = async (data: ValidateOtp): Promise<any> => {
    try {
        if (isEmpty(data)) return null;
        const response = await validateOtp(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return response?.data;
        } else return response?.data?.responseData?.responseMessage;
    } catch (error) {
        captureMessagesViaErrorLogger(error);
    }
};

export const getInvestorDrawdowns = async ({
    input,
    onSuccess,
}: {
    input: object;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchDrawdownOrders(input);
        if (res?.status === 200) onSuccess(res?.data?.data);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getInvestorGraphsHelper = async (
    dateRange: object,
    graphType: string,
    investorOrgId: string,
): Promise<any> => {
    try {
        const res = await fetchInvestorPortfolioGraphs(dateRange, graphType, investorOrgId);
        if (res?.status === 200) return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getOffers = async ({
    investorOrganizationId,
    onSuccess,
}: {
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchOffers(investorOrganizationId);
        if (res.status === 200) return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getAllDeals = async ({
    investorOrganizationId,
    onSuccess,
}: {
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchAllDeals(investorOrganizationId);
        if (res.status === 200) {
            onSuccess(res?.data?.deals);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getUnderwritingMapping = async ({
    investorOrganizationId,
}: {
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchUnderwritingMapping(investorOrganizationId);
        if (res.status === 200) {
            return res?.data?.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getInvestorOpsMapping = async ({
    investorOrganizationId,
}: {
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchInvestorOpsMapping(investorOrganizationId);
        if (res.status === 200) {
            return res?.data?.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTdsFilingReportDownload = async (url: string, data: monthYear): Promise<any> => {
    try {
        const res = await fetchTdsFilingReportDownload(url, data);
        if (res.status === 200) {
            return res?.data?.data;
        }
    } catch (err: any) {
        captureMessagesViaErrorLogger(err);
        if (err?.response?.data) {
            throw err.response.data;
        }
        throw err;
    }
};

export const acceptTotAndESign = async (
    investee_organization_id: string,
    stage_tot_id: string,
): Promise<any> => {
    try {
        const res = await postAcceptTotAndESign(investee_organization_id, stage_tot_id);
        if (res.status === 200) {
            return res.data;
        } else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const resendSignReq = async (investee_organization_id: string): Promise<any> => {
    try {
        const res = await postResendSignReq(investee_organization_id);
        if (res.status === 200) {
            return res.data;
        } else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTotData = async (investeeOrganizationId: string, totId?: string): Promise<any> => {
    try {
        const res = await fetchTotData(investeeOrganizationId, totId);
        if (res.status === 200) return res?.data?.data;
        else return res?.data?.data?.message ?? GENERIC_ERROR;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getDebtTotData = async (payload: debtPayload): Promise<any> => {
    try {
        if (!payload.identifierId || !payload.identifierType)
            showNotification('error', 'Some Error occured. Please try again');
        const res = await fetchDebtTotData(payload);
        if (res.status === 200) return res?.data?.data;
        else return res?.data?.data?.message ?? GENERIC_ERROR;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getInvestmentDetails = async ({
    investeeOrganizationId,
    investorOrganizationId,
    onSuccess,
}: {
    investeeOrganizationId: string;
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchInvestmentDetails(investeeOrganizationId, investorOrganizationId);
        if (res?.status === 200) onSuccess(res?.data?.data);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getCompanyOverview = async ({
    investeeOrganizationId,
    onSuccess,
}: {
    investeeOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchCompanyOverview(investeeOrganizationId);
        if (res?.status === 200) {
            onSuccess(res?.data?.data);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalAccepted = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalAccepted(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalAcceptedForDebt = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalAcceptedForDebt(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const setWhatsappPreference = async (data: WhatsappPreference) => {
    try {
        const res = await whatsappPreference(data);
        if (res?.status === 204) {
            return { status: 'success', message: 'Updated Preference Successfully' };
        }
        return { status: 'error', message: 'Failed to update preference' };
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTotalSanctioned = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalSanctioned(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalSanctionedForDebt = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalSanctionedForDebt(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTotalDisbursed = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalDisbursed(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getMonthlyDisbursmentForDebt = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchMonthlyDisbursmentsForDebt(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getMonthlyDisbursment = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchMonthlyDisbursments(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getFeeAndTenure = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchFeeAndTenure(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getPrincipalOutstanding = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchPrincipalOutstanding(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getAverageRecurGrade = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchAverageRecurGrade(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getRepaymentHistoryGraph = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchRepaymentHistoryGraph(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getDpdView = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchDpdView(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalAcceptedDrilldown = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalAcceptedDrilldown(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalAcceptedDrilldownForDebt = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchTotalAcceptedDrilldownForDebt(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalSanctionedDrilldown = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchSanctionDrilldown(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getTotalSanctionedDrilldownForDebt = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchSanctionDrilldownForDebt(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTotalDisburedDrilldown = async (investeeOrganizationId: string) => {
    try {
        const res = await fetchDisbursmentDrilldown(investeeOrganizationId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
        return res?.data?.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getInvesteeDpdData = async ({
    investeeOrganizationId,
    investorOrganizationId,
    onSuccess,
}: {
    investeeOrganizationId: string;
    investorOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchInvesteeDpdData(investeeOrganizationId, investorOrganizationId);
        if (res?.status === 200) {
            onSuccess(res?.data?.data);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getPGConnections = async ({
    companyId,
    requestId,
    onSuccess,
}: {
    companyId: string;
    requestId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchPGConnections(companyId, requestId);
        if (res?.status === 200) {
            onSuccess(res?.data?.connectedPGList);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const sendRazorPG = async ({ input, PGList }: { input: any; PGList: any }): Promise<any> => {
    try {
        let res;
        for (let obj of PGList) {
            if (obj?.accountId === input?.pgDetails?.accountId) {
                res = await editRazorPG(input);
                break;
            }
        }
        if (isEmpty(res)) res = await registerRazorPG(input);
        if (res?.status === 200) {
            const PgArr = res?.data?.connectedPGList;
            const showSuccess =
                (PgArr.find((obj: any) => obj.accountId === input?.pgDetails?.accountId) ?? {})
                    ?.status === 'CONNECTED';
            if (showSuccess)
                showNotification(
                    SHOW_NOTIFICATION_STATUS.SUCCESS,
                    'Razorpay Registration Successful',
                );
            else {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    'Razorpay Registration Unsuccessful',
                    '',
                    1000,
                );
                return { showModal: true, pgList: res?.data?.connectedPGList };
            }
            return { showModal: false, pgList: res?.data?.connectedPGList };
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getRcmData = async ({
    investeeOrganizationId,
    onSuccess,
}: {
    investeeOrganizationId: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchRcmData(investeeOrganizationId);
        if (res?.status === 200) {
            onSuccess(res?.data?.data);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getAllDealsLinkedToATot = async (data: {
    investee_organization_id: string;
    tot_id: string;
}): Promise<any> => {
    try {
        const res = await fetchAllDealsLinkedToATot(data);
        if (res?.status === 200) return res?.data?.data;
        const message = res?.data?.data?.message ?? GENERIC_ERROR;
        console.error('While fetching deals: ', message);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};
export const getSignedUrlToUpload = async ({
    input,
    orgId,
}: {
    input: object;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await fetchSignedUrlToUpload(input, orgId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS) return res.data;
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const esignDocsAndMakeCompanyActive = async ({
    investee_organization_id,
    onSuccess,
}: {
    investee_organization_id: string;
    onSuccess?: (investeeOrgData: any) => void;
}): Promise<any> => {
    try {
        const res = await callApiToEsignDocsAndMakeCompanyActive({ investee_organization_id });
        if (res?.status === 200) {
            if (onSuccess) onSuccess(res?.data?.investeeOrgData);
        } else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        console.error(err);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        captureMessagesViaErrorLogger(err);
    }
};
export const saveDocMetaDataInDocService = async ({
    input,
    orgId,
}: {
    input: object;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await saveMetaDataInDocService(input, orgId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS)
            return res?.data?.fileMetadata;
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchIsNameMatchWithPanFailed = async (data: object): Promise<any> => {
    try {
        const res = await isNameMatchWithPanFailed(data);
        if (res?.status === 200) return res?.data?.data;
        throw new Error(res?.data?.data?.message ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const performUpdateNameFetchedFromPan = async (data: object): Promise<any> => {
    try {
        const res = await updateNameFetchedFromPan(data);
        if (res?.status === 200) return res?.data;
        throw new Error(res?.data?.data?.message ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const saveKycDocMetaData = async ({
    input,
    investeeOnboardingId,
    purpose,
    section,
    onSuccess,
}: {
    input: any;
    investeeOnboardingId: string;
    purpose: string;
    section: string;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await updateKycMetaData(input, investeeOnboardingId, purpose, section);
        if (res?.status === 200) {
            if (onSuccess) onSuccess();
        } else throw new Error(res?.data?.data?.message ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const legalitySign = async ({
    data,
    onSuccess,
    doFinally,
}: {
    data: any;
    onSuccess: (signUrl: string) => void;
    doFinally: () => void;
}) => {
    try {
        const res = await callApiForLegalitySign(data);
        if (res?.status === 200) {
            if (onSuccess) onSuccess(res?.data?.data?.sign_url);
        } else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err: any) {
        console.error(err);
        if (err?.response?.data?.data?.message === 'User not allowed to sign this document.')
            showNotification('Error', 'You are not authorized to sign this document');
        else showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        captureMessagesViaErrorLogger(err);
    } finally {
        doFinally();
    }
};
export const deleteDocument = async ({
    input,
    investeeOnboardingId,
    onSuccess,
}: {
    input: object;
    investeeOnboardingId: string;
    onSuccess?: Function;
}): Promise<any> => {
    try {
        const res = await deleteDoc(input, investeeOnboardingId);
        if (res?.status === 200) {
            if (onSuccess) onSuccess();
        } else throw new Error(res?.data?.data?.message ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};
export const getCompanySidebarConfigTab = async (data: Object) => {
    try {
        const res = await callApiToGetSidebarConfigTab(data);
        if (res?.status === 200) return res?.data?.companyTabsConfig;
        else return false;
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const getDoesCompanyContainNotMetCompliance = async (data: String) => {
    try {
        const res = await callApiToGetDoesCompanyContainNotMetCompliance(data);
        if (res?.status === 200) return res?.data;
        else return false;
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchWidgetData = async (data: Object) => {
    try {
        const res = await callApiToGetWidgetData(data);
        if (res?.status === 200) return res?.data?.widget;
        else return false;
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const getPresignedUrl = async ({ docId }: { docId: number }): Promise<any> => {
    const getFileType = (mimeType: string): string => {
        if (mimeType === 'application/.pdf') return 'application/pdf';
        if (mimeType === 'application/text') return 'text/plain';
        return mimeType;
    };
    try {
        const res = await downloadFilePresigned(docId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS)
            return {
                uri: res?.data?.signedUrl,
                fileType: getFileType(res?.data?.mimeType),
            };
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
    }
};
export const getCollectionDashboardData = async (investeeOrgId: string) => {
    try {
        const res = await fetchCollectionDashboardData(investeeOrgId);
        if (res?.status === 200) {
            return res?.data?.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const viewDownloadKycDocuments = async ({
    input,
    onSuccess,
}: {
    input: object;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await fetchKycDocuments(input);
        if (res?.status === 200) {
            onSuccess(res?.data?.data);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getCompanyCollectionsHistory = async (
    investeeOrganizationId: string,
    startDate?: string,
    endDate?: string,
) => {
    try {
        const res = await fetchCompanyCollectionsHistory(
            investeeOrganizationId,
            startDate,
            endDate,
        );
        if (res?.data?.responseData?.responseCode === 20) {
            return res?.data?.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getCoborrowerDetails = async (stageId: string, investeeOrgId?: string) => {
    try {
        const res = await fetchCoborrowerDetails(stageId, investeeOrgId);
        if (res?.status === 200) {
            return res?.data?.data.map((item: any) => {
                return {
                    ...item,
                    coborrowerDocuments: item?.coborrowerDocuments?.map((doc: any) => ({
                        ...doc,
                        doc_name: doc.docName,
                        doc_link: doc.docLink,
                    })),
                };
            });
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getInviteCoborrower = async (input: object) => {
    try {
        const res = await callInviteCoborrower(input);
        if (res?.status === 200) {
            return res?.data;
        }
    } catch (err: any) {
        captureMessagesViaErrorLogger(err);
        showNotification('error', err.response.data.data.message);
        return false;
    }
};
export const updateCoborrowersDetails = async (input: object) => {
    try {
        const res = await callUpdateCoborrowerDetails(input);
        if (res?.status === 200) {
            return res?.data?.data;
        }
    } catch (err: any) {
        captureMessagesViaErrorLogger(err);
        showNotification('error', err.response.data.data.message);
    }
};

export const getAddOnLimitStatusHelper = async (investeeOrgId: string): Promise<any> => {
    try {
        const res = await fetchAddOnStatus(investeeOrgId);
        if (res?.status === 200) return res.data?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            throw new Error(message);
        }
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const getMonthlyOnboardingStatus = async (
    investeeOrgId: string,
    path: string,
): Promise<any> => {
    try {
        const res = await fetchMonthlyOnboardingStatus(investeeOrgId, path);
        if (res?.status === 200) return res.data?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            throw new Error(message);
        }
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const getIntegrations = async (investeeOnboardingId: string): Promise<any> => {
    try {
        const res = await fethcIntegrations(investeeOnboardingId);
        if (res?.status === 200) {
            return res.data;
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const submitAddOnLimitData = async (
    investeeOnboardingId: string,
    path: string,
    onSuccess: () => void,
): Promise<any> => {
    try {
        const res = await callApiToSubmitAddOnLimitData(investeeOnboardingId, path);
        if (res.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            throw new Error(message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getFinboxSessionUrl = async (data: object): Promise<any> => {
    try {
        const res = await fetchFinboxSessionUrl(data);
        if (res?.status === 200) return res.data?.data?.redirect_url;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            throw new Error('While fetching Finbox session url: ' + message);
        }
    } catch (err) {
        console.error(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const updateMetaData = async ({
    investeeOnboardingId,
    stage,
    subStage,
    path,
    data,
    onSuccess,
    onFailure,
}: {
    investeeOnboardingId: string;
    stage: string;
    subStage: string;
    path: string | null | undefined;
    data: object;
    onSuccess: Function;
    onFailure?: Function;
}): Promise<any> => {
    try {
        const res = await callApiToUpdateMetaData({
            investeeOnboardingId,
            stage,
            subStage,
            path,
            data,
        });
        if (res?.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            if (onFailure) onFailure();
            else {
                const message = res?.data?.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const updateMetaDataV3 = async ({
    investeeOnboardingId,
    stage,
    subStage,
    source,
    path,
    data,
    onSuccess,
    onFailure,
}: {
    investeeOnboardingId: string;
    stage: string;
    subStage: string;
    path: string | null | undefined;
    source?: string;
    data: object;
    onSuccess: Function;
    onFailure?: Function;
}): Promise<any> => {
    try {
        const res = await callApiToUpdateMetaDataV3({
            investeeOnboardingId,
            stage,
            subStage,
            source,
            path,
            data,
        });
        if (res?.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            if (onFailure) onFailure(res.data);
            else {
                const message = res?.data?.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const getUploadSignedUrl = async (data: object): Promise<any> => {
    try {
        const res = await fetchUploadSignedUrl(data);
        if (res?.status === 200) return res.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const deleteFile = async ({
    investeeOnboardingId,
    path,
    data,
    onSuccess,
    doFinally,
}: {
    investeeOnboardingId: string;
    path: string;
    data: object;
    onSuccess: Function;
    doFinally: Function;
}): Promise<any> => {
    try {
        const res = await callApiToDeleteFile({ investeeOnboardingId, path, data });
        if (res?.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    } finally {
        if (doFinally) doFinally();
    }
};

export const getMonthlyViewData = async (investeeOrgId: string): Promise<any> => {
    try {
        const res = await callApiToFetchMonthlyViewData(investeeOrgId);
        if (res.status === 200) return res.data?.data;
        const message = res?.data?.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};
export const getTdsClaimsData = async (payload: TdsClaimsInterface): Promise<any> => {
    try {
        if (payload?.allTime) {
            const res = await fetchTdsClaimDataAllTime(payload);
            if (res.status === 200) {
                if (res.data.responseData.responseCode === 20) {
                    return res.data?.data;
                } else
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        res.data.responseData.responseMessage ?? GENERIC_ERROR,
                    );
            }
        } else {
            const res = await fetchTdsClaimData(payload);
            if (res.status === 200) {
                if (res.data.responseData.responseCode === 20) {
                    return res.data?.data;
                } else
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        res.data.responseData.responseMessage ?? GENERIC_ERROR,
                    );
            }
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};
export const updatingTdsClaimsData = async (payload: TdsClaimsInterface): Promise<any> => {
    try {
        const res = await updateTdsClaimData(payload);
        if (res.status === 200) {
            if (res.data.responseData.responseCode === 20) {
                return res.data?.data;
            } else
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    res.data.responseData.responseMessage,
                );
        }
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    }
};

export const updateGlobalNeedInfoHelper = async (payload: GlobalNeedInfoProps): Promise<any> => {
    try {
        const res = await updateGlobalNeedInfo(payload);
        if (res.status === 200) {
            if (res.data.responseData.responseCode === 20) {
                return res.data;
            } else
                showNotification(
                    SHOW_NOTIFICATION_STATUS.ERROR,
                    res.data.responseData.responseMessage,
                );
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const saveIntegratedSource = async ({
    source,
    data,
    path,
    onSuccess,
    doFinally,
}: {
    source: string;
    data: object;
    path: string;
    onSuccess?: Function;
    doFinally: Function;
}): Promise<any> => {
    try {
        const res = await callApiToIntegrateSource({ source, data, path });
        if (res.status !== 200) {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            console.error('Integration Error: ', message);
        }
        if (onSuccess) onSuccess();
    } catch (err) {
        console.log(err);
        captureMessagesViaErrorLogger(err);
    } finally {
        if (doFinally) doFinally();
    }
};

export const acceptAddOnTot = async ({
    totId,
    data,
    onSuccess,
}: {
    totId: string;
    data: object;
    onSuccess?: Function;
}): Promise<any> => {
    try {
        const res = await callApiToAcceptAddOnTot({ totId, data });
        if (res.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getSecondaryRcmData = async (orgId: string): Promise<any> => {
    try {
        const res = await fetchSecondaryRcmData(orgId);
        if (res.status === 200) return res.data?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const saveContactUsData = async (data: object, onSuccess?: () => void): Promise<any> => {
    try {
        const res = await callApiToSaveContactUsData(data);
        if (res.status === 200) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getAllNachDetails = async (totId: string): Promise<any> => {
    try {
        const res = await callApiToFetchAllNach(totId);
        if (res.status === 200) return res?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return res.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const getSetupNach = async (data: object): Promise<any> => {
    try {
        const res = await callApiToSetupNach(data);
        if (res.status === 200) return res?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const getDealsV2 = async (
    investorOrgId: string,
    currentTab: string,
    companyName?: string,
): Promise<any> => {
    try {
        const res = await callApiToFetchDeals(investorOrgId, currentTab, companyName);
        if (res.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) return res.data.data;
            else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getDocVerificationDeals = async (
    currentTab: string,
    investorOrgId: string,
    companyName?: string,
): Promise<Deal[] | null> => {
    try {
        const res = await callApiToGetDocVerificationDeals(currentTab, investorOrgId, companyName);
        if (res?.status === STATUS_CODE.SUCCESS) {
            if (res.data?.responseData?.internalResponseCode === INTERNAL_RESPONSE_CODE.SUCCESS)
                return res.data.data as Deal[];
            else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                //showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return [];
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};
export const getPendingDrawdowns = async (
    investorOrgId: string,
    companyName?: string,
): Promise<Deal[] | null> => {
    try {
        const res = await callApiToGetPendingDrawdowns(investorOrgId, companyName);
        if (res?.status === STATUS_CODE.SUCCESS) {
            if (res.data?.status === 'Success') return res.data.data as Deal[];
            else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                return null;
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getPortfolioDeals = async (
    portfolioDealStatus: string,
    investorOrganizationId: string,
    companyName?: string,
): Promise<any> => {
    try {
        const res = await callApiToFetchPortfolioDeals(
            portfolioDealStatus,
            investorOrganizationId,
            companyName,
        );
        if (res.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) return res.data.totalDeals;
            else {
                return [];
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getUpdateNachSignatory = async (data: object): Promise<any> => {
    try {
        const res = await callApiToUpdateNachSignatory(data);
        if (res.status === 200) return res?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getSelectNachType = async (data: object): Promise<any> => {
    try {
        const res = await callApiToSelectNachType(data);
        if (res.status === 200) return res?.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getUploadPhysicalNach = async (data: {
    mandateId: string | number;
    uploadedDocId: string | number;
    uploadedDocName: string;
}): Promise<any> => {
    try {
        const res = await callApiToUploadPhysicalNach(data);
        if (res.status === 200 && res.data.responseCode === 20) return res?.data;
        else {
            const message = res.data.errorMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
interface UpdateInvestorDealStatusData {
    offerId?: string;
    investorOrganizationId?: string;
    reviewedBy?: string;
    offerStatus?: string;
}

export const updateInvestorDealStatus = async ({
    data,
    onSuccess,
}: {
    data: UpdateInvestorDealStatusData;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await callApiToUpdateInvestorDealStatus(data);
        if (res.status === 200 && res.data?.status === API_STATUS.SUCCESS) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getDownloadFileBulk = async (data: object): Promise<any> => {
    try {
        const res = await downloadFileBulk(data);
        if (res.status === 200) return res;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Couldn't download file");
        return false;
    }
};

export const updateDeal = async ({
    data,
    onSuccess,
}: {
    data: object;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await callApiToUpdateDeal(data);
        if (res.status === 200 && res.data?.status === API_STATUS.SUCCESS) {
            if (onSuccess) onSuccess();
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const saveFeedback = async (data: object, onSuccess: Function): Promise<any> => {
    try {
        const res = await callApiToSaveDealFeedback(data);
        if (res.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) {
                if (onSuccess) onSuccess();
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getCibilVerificationStatus = async (data: {
    investeeOrgId: String;
    fetchDetail?: boolean;
}): Promise<any> => {
    try {
        const res = await fetchcibilVerificationStatus(data);
        if (res.status === 200) return res.data;
        else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const sendCibilVerificationRequestMessage = async (data: object): Promise<any> => {
    try {
        const res = await sendCibilVerificationRequest(data);
        if (res.status === 200) return res.data;
        else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const verifyGstinHelper = async (body: object): Promise<any> => {
    try {
        const res = await verifyGstin(body);
        if (res.status === 200) return res.data;
        else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return res.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const sendCibilVerificationStatusHelper = async (data: string): Promise<any> => {
    try {
        const res = await sendCibilVerificationStatus(data);
        if (res.status === 200) return res.data;
        else {
            const message = res.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const fetchDealConversation = async (offerId?: string): Promise<any> => {
    try {
        const res = await callApiToFetchDealConversation(offerId);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) return res.data.conversationData;
            else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const saveMessageOrDraftForDealConversation = async (
    //insert validation for payload
    data: object,
    onSuccess?: Function,
): Promise<any> => {
    try {
        const res = await callApiToSaveMessageOrDraftForDealConversation(data);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                if (onSuccess) onSuccess();
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const deleteFileFromDocService = async ({
    docId,
    onSuccess,
}: {
    docId: number;
    onSuccess: Function;
}): Promise<any> => {
    try {
        const res = await callApiToDeleteFileFromDocService(docId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS) onSuccess();
        else throw new Error(res?.data?.responseData?.responseMessage ?? GENERIC_ERROR);
    } catch (err) {
        console.error(err);
    }
};

export const fetchCompanyMatchPercentage = async ({
    data,
}: {
    data: {
        investeeOrganizationId: string;
        investorOrganizationId: string;
        dealId: string;
        loanType?: string | null;
    };
}): Promise<any> => {
    try {
        const loanType = data?.loanType;
        delete data?.loanType;
        const res = await callAptToFetchCompanyMatchPercentage(data);
        if (res.data?.responseData?.internalResponseCode === 20) {
            return res.data.data;
        } else {
            if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION) {
                return;
            }
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getWaitlistedData = async (data: {
    identifierId: string | number;
    requestId: string | number;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await FetchWaitlistedData(data);
        return res.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

// updateIndicativeTerms in dealOffers
export const updateIndicativeTerms = async ({
    data,
    onSuccessFn,
}: {
    data: IndicativeTerms;
    onSuccessFn: () => void;
}): Promise<Boolean> => {
    try {
        const res = await callApiToUpdateIndicativeTerms(data);
        if (res.status === 200 && res.data?.responseData?.responseCode === 40) {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        } else if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            if (onSuccessFn) onSuccessFn();
            if (data?.sharedIpaWithCompany !== IPA_ACCEPTED) {
                showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Indicative Terms Updated');
            }
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
        return true;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const fetchLimitOverview = async (data: object): Promise<any> => {
    try {
        const res = await callApiToUpdateLimitOverview(data);
        if (res.data?.status === 'Success') {
            return res.data?.data;
        } else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getRecurScaleOffers = async (): Promise<any> => {
    try {
        const res = await fetchRecurScaleOffers();
        if (res.data?.responseData.responseCode === INTERNAL_RESPONSE_CODE.SUCCESS) {
            return res.data?.data;
        } else {
            //@TODO check this error
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchInvesteeCapitalAvailableData = async (investeeOrgId: string): Promise<any> => {
    try {
        const res = await callAptToFetchCapitalAvailableData(investeeOrgId);
        if (res.data?.responseData?.responseCode === 20) return res.data.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchInvesteeCollectionMode = async (investeeOrgId: string): Promise<any> => {
    try {
        const res = await callAptToFetchCollectionMode(investeeOrgId);
        if (res.data?.responseData?.responseCode === 20) return res.data.collectionModeAndText;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const updateDocumentationStatusHelper = async (data: {
    reviewedBy: string | null;
    investorDealId: string | null;
    documentationStatus: string | null;
}): Promise<any> => {
    try {
        const res = await callApiToUpdateDocumentationStatus(data);
        if (res?.status === STATUS_CODE.SUCCESS) {
            if (res.data?.responseData?.internalResponseCode === INTERNAL_RESPONSE_CODE.SUCCESS) {
                const message = res.data?.responseData?.responseMessage;
            } else {
                const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const fetchRepaymentSchedule = async (data: object): Promise<any> => {
    try {
        const res = await callAptToFetchRepaymentSchedule(data);
        if (res.status === 200 && res.data?.status === API_STATUS.SUCCESS) return res.data.data;
        const message = res?.data?.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchForecastData = async (investeeOrgId: string) => {
    try {
        const res = await callApiToFetchForecastData(investeeOrgId);
        if (res.status === 200 && res.data?.statusCode === 200) return res.data.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const getTdsClaimsForInvestor = async (investorOrgId: string): Promise<any> => {
    try {
        const res = await fetchTdsClaimsForInvestor({ investorOrgId });
        if (res?.data?.responseData?.responseCode === 20) return res?.data?.data;
        else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Unable to get TDS Claims');
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const updateTdsClaimsForInvestor = async (payload: object): Promise<any> => {
    try {
        const res = await postTdsClaimForInvestor(payload);
        if (res?.data?.responseData?.responseCode === 20) return res?.data;
        else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Unable to update this TDS Claim');
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
/**
 * collecting the response from the saveODFDData api. It returns the updated whole investeeOnboardingData Document
 * @param data
 * @returns
 */
export const updateODFDData = async (data: object, path: string): Promise<any> => {
    try {
        const res = await callApiToUpdateODFDdata(data, path);
        let returnData = {
            investeeOnboardingData: res.data?.data,
        };

        if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            return returnData;
        } else if (res.status === 200 && res.data?.responseData?.responseCode === 40) {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const updatingActiveLoanCheck = async (data: object): Promise<any> => {
    try {
        const res = await callApiToUpdateActiveLoanOrDebt(data);
        if (res.status === 200 && res.data?.status === API_STATUS.SUCCESS) return res.data.data;
        const message = res?.data?.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const updatingActiveLoanCheckNeedInfoOnboarding = async (data: object): Promise<any> => {
    try {
        const res = await callApiToUpdateLoanCheckInNeedInfoOnboarding(data);
        if (res.status === 200 && res.data?.status === API_STATUS.SUCCESS) return res.data.data;
        const message = res?.data?.data?.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const getCounterSignLink = async (encryptedDealId: string): Promise<any> => {
    try {
        const res = await getCounterSignLinkFromEncryptedDealId({ encryptedDealId });
        if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            return res.data?.data;
        }
        const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        return null;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return null;
    }
};

export const callRegenerateCounterSigningLink = async (data: { dealId: string }): Promise<any> => {
    try {
        const res = await regenerateCounterSigningLink(data);
        if (res.status === 200 && res.data?.responseData?.responseCode === 20) {
            return res.data?.data;
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const getCounterSignUserApi = async (investorOrgId: string, requestId: string) => {
    try {
        const res = await callApiToGetCounterSignUser(investorOrgId, requestId);
        if (res.data?.status === API_STATUS.SUCCESS) {
            return res.data;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const updatePEC = async (data: any) => {
    try {
        const res = await updatePecDetails(data);
        if (res.data?.responseData?.internalResponseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'PEC Updated Succesfully');
            return res.data;
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Unable to update PEC');
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const fetchTotNachStatusForCompany = async (orgId: string) => {
    try {
        const res = await fetchNachStatusOldLines(orgId);
        if (res.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return res.data;
        } else {
            const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            captureMessagesViaErrorLogger(message);
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getDealManagerMapping = async ({
    investorOrganizationId,
    requestId,
}: {
    investorOrganizationId: string;
    requestId: string;
}): Promise<any> => {
    try {
        const res = await getDealManagerData(investorOrganizationId, requestId);
        if (res.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return res?.data?.data;
        } else {
            return null;
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const fetchInvesteeNoOfChartsUnlocked = async (investeeOrgId: string) => {
    try {
        const res = await fetchNoOfChartsUnlocked(investeeOrgId);
        let returnData = {
            benchmarkCharts: res.data?.data,
        };
        if (res) {
            return returnData;
        } else {
            // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
            return false;
        }
    } catch (err) {
        // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        return false;
    }
};

export const getARRGrowthPercentile = async (investeeOrgId: string) => {
    try {
        const res = await fetchARRGrowthPercentile(investeeOrgId);
        let returnData = {
            arrGrowthPercentileData: res.data?.data,
        };
        if (res) {
            return returnData;
        } else {
            // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
            return false;
        }
    } catch (err) {
        // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        return false;
    }
};

export const fetchInsightsGrowthData = async (startingDate: any, endingDate: any) => {
    try {
        const res = await fetchInsightsGrowth(startingDate, endingDate);
        const returnData = {
            insightsGrowthRate: res.data?.tabData,
        };
        if (res) {
            return returnData;
        } else {
            // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
            return false;
        }
    } catch (err) {
        // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        return false;
    }
};

export const getKycData = async (onboardingId: string, dispatch: Function) => {
    try {
        const res = await fetchKycAppData(onboardingId);
        if (res.data.status === API_STATUS.SUCCESS && res.data)
            updateKycDetailsToLatest(res.data.data, dispatch);
        else {
            let message;
            if (res.data.message) message = res.data.message;
            else if (res.data && res.data.message) message = res.data.message;
            else message = 'Some error occurred!';
            showNotification('Error', message);
        }
    } catch (err) {
        showNotification('Error', 'Some error occurred!');
    }
};

export const getCsvForZeusUser = async (data: object): Promise<any> => {
    try {
        const res = await fetchCsvForZeusUser(data);
        if (res.status === 200) return res.data;
        else {
            const message = res?.data?.data?.message ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (e) {
        captureMessagesViaErrorLogger(e);
        return false;
    }
};

export const getCsvForTdsDeposit = async (data: object): Promise<any> => {
    try {
        const res = await fetchCsvForTdsDeposit(data);
        if (res.status === 200 && res.data?.type === 'application/octet-stream') {
            return res.data;
        }
        showNotification(
            SHOW_NOTIFICATION_STATUS.ERROR,
            'Unable to download file. Please try again later.',
        );
    } catch (e) {
        captureMessagesViaErrorLogger(e);
    }
    return false;
};

export const editIpaDocInDealOffers = async ({
    input,
    orgId,
    operationType,
}: {
    input: object;
    orgId: string;
    operationType: string;
}): Promise<any> => {
    try {
        const res = await editIpaDocInDealOffer(input, orgId, operationType);
        if (res?.status === STATUS_CODE.SUCCESS) {
            return true;
        }
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getDownloadLedgerZip = async (data: object, responseType: any): Promise<any> => {
    try {
        const res = await getScheduledInvestorLedgerExcel(data, responseType);
        if (res.status === 200) return res;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Couldn't download file");
        return false;
    }
};
export const registerClaim = async (data: RegisterClaimDataPayload): Promise<any> => {
    try {
        const res = await callRegisterClaim(data);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const getClaim = async (
    customerQueryId: string | null,
    requestBy?: string | null,
): Promise<any> => {
    try {
        const res = await fetchTdsClaim(customerQueryId, requestBy);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const getInvestorsTdsInfo = async (claimId: string | null): Promise<any> => {
    try {
        const res = await fetchInvestorInfo(claimId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const updateClaim = async (data: UpdateClaimPayload): Promise<any> => {
    try {
        const res = await callUpdateClaim(data);
        if (res.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};
export const getDSCStatus = async (docId: string | number): Promise<any> => {
    try {
        const res = await fetchDscStatus(docId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 'OK') {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const fetchCustomerQueries = async (
    docId: string,
    isInvestor: boolean,
    ticketType?: string,
    requestBy?: string,
): Promise<any> => {
    try {
        const res = await getCustomerQueries(docId, isInvestor, ticketType, requestBy);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const fetchCpForTotId = async (totId: string): Promise<ConditionPrecedent[]> => {
    try {
        const res = await getCpForTotId(totId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data?.cps as ConditionPrecedent[];
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
    }
    return [];
};

export const submitCpDetails = async (payload: any): Promise<ConditionPrecedent[] | null> => {
    try {
        const res = await updateCpDataForCompany(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) {
                showNotification(
                    SHOW_NOTIFICATION_STATUS.SUCCESS,
                    'Conditions successfully marked as met',
                );
                return res.data?.data?.cps as ConditionPrecedent[];
            }
            const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getTdsDepositDates = async (
    investeeOrgId: string,
): Promise<tdsDatesProps[] | null> => {
    try {
        const res = await fetchTdsDepositdates(investeeOrgId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            }
            const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getBalanceDrawdowns = async (data: object): Promise<any> => {
    try {
        const res = await fetchDrawdowns(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
    }
    return false;
};

export const getRegisterBalanceConfirmation = async (data: object): Promise<any> => {
    try {
        const res = await registerBalanceConfirmation(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const registerSupportQuery = async (data: object): Promise<any> => {
    try {
        const res = await registerSupportQueryTicket(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const getBalanceConfirmationData = async (data: string): Promise<any> => {
    try {
        const res = await fetchBalanceConfirmationData(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const getSupportQueryData = async (data: string): Promise<any> => {
    try {
        const res = await getSupportQueryApi(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const updateBalanceConfirmation = async (data: object): Promise<any> => {
    try {
        const res = await updateBalanceConfirmationApi(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
        return false;
    }
};

export const getTdsPendingForOrders = async (data: object): Promise<any> => {
    try {
        const res = await getAllTdsPendingForOrders(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        await captureMessagesViaErrorLogger(err);
    }
    return false;
};

export const getApplicationStr = async (
    orgId: string,
    identifierId: string,
    identifierType: string,
): Promise<any> => {
    try {
        const res = await fetchApplicationStrength(orgId, identifierId, identifierType);
        if (res?.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const partiallySubmitApplicationStrength = async (
    orgId: string,
    identifierId: string,
    identifierType: string,
): Promise<any> => {
    try {
        const res = await callPartiallySubmitApplication(orgId, identifierId, identifierType);
        if (res?.status === 200) {
            if (res.data?.responseData?.internalResponseCode === 20) {
                return res.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getInvestorWiseCollectionData = async (orgId: string) => {
    try {
        const res = await fetchInvestorWiseCollectionData(orgId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};
export const getParsedDocumentForTotalRepayments = async ({
    docId,
    extractionConfigId,
}: {
    docId: string;
    extractionConfigId: string;
}) => {
    try {
        const res = await callParseDocument({ docId, extractionConfigId });
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
        return false;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};
export const getParsedData = async (textractReferenceId: string) => {
    try {
        const res = await fetchParsedData(textractReferenceId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const postTotalRepaymentProof = async (data: object) => {
    try {
        const res = await submitTotalRepaymentProof(data);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const getRecordedPaymentAmount = async (orgId: string) => {
    try {
        const res = await fetchRecordedPaymentAmount(orgId);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export const fetchMobileUploadeStepsHelper = async ({
    investeeOrgId,
}: {
    investeeOrgId: string;
}): Promise<any> => {
    try {
        const response = await fetchMobileUploadingSteps(investeeOrgId);
        if (response.status === 200) {
            const data = response.data?.data?.mobileUploadingData;
            return data;
        } else {
            const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
};

export const sentNotificationViaMailForMobileHelper = async ({
    investeeOnboardingId,
}: {
    investeeOnboardingId: string;
}): Promise<any> => {
    try {
        const response = await sentNotificationViaMailForMobile(investeeOnboardingId);
        if (response.status === 200) {
            const message = 'Email Sent Successfully';
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, message);
            return response;
        } else {
            const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        }
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
};

export const reapplyForOnboardingHelper = async ({
    orgId,
    expectedAmount,
    expectedTimeline,
}: {
    orgId: string;
    expectedAmount: string;
    expectedTimeline: string;
}): Promise<boolean> => {
    try {
        const response = await reapplyForOnboarding({ orgId, expectedAmount, expectedTimeline });
        if (response.status === 200) {
            const message = 'Reapplied for financing successfylly';
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, message);
            return true;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
    return false;
};
export const fetchRecordedPayments = async (payload: object): Promise<any> => {
    try {
        const res = await getRecordedPayments(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return res.data?.data;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const markRecordedPaymentStatus = async (payload: object): Promise<any> => {
    try {
        const res = await updateRecordPaymentStatus(payload);
        if (res?.status === 200) {
            if (res.data?.responseData?.responseCode === 20) {
                return true;
            } else {
                const message = res.data?.responseData?.responseMessage ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
                return false;
            }
        }
    } catch (err) {
        return false;
    }
};

export const fundingCalculationHelper = async ({ fundingInput }: any): Promise<any> => {
    try {
        const response = await fundingCalculation(fundingInput);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
    return false;
};

export const updateGSTAddressHelper = async ({ gstData }: any): Promise<any> => {
    try {
        const response = await updateGSTAddress(gstData);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
    return false;
};

export const getConfigForAdditionalDocs = async (data: { configName: string }): Promise<any> => {
    try {
        const response = await fetchConfigForAdditionalDocs(data);
        if (response.status === 200) {
            return response.data?.responseBody;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
};

export async function getCompaniesLegalNamesList(payload: any, handlers: any) {
    try {
        const response = await getCompaniesLegalNamesListService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.autocompleteResultList || []);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
                break;
        }
    } catch (error) {
        handlers?.onError?.();
        showNotification(
            SHOW_NOTIFICATION_STATUS.ERROR,
            'Error fetching application details config',
        );
    }
}
export const fetchDealManagerHelper = async (offerId: string): Promise<any> => {
    try {
        const response = await fetchDealManager(offerId);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
};

export const addDealManagerHelper = async (data: Object): Promise<any> => {
    try {
        const response = await addDealManager(data);
        if (response.status === 200) {
            return response.data?.data;
        }
        const message = response.data?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    } catch (err) {
        const message = GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        captureMessagesViaErrorLogger(err);
    }
};

export const getLenderPlatfromFeePercent = async (investorDealId: string): Promise<any> => {
    try {
        const res = await fetchLenderPlatfromFeePercent(investorDealId);
        if (res?.status === STATUS_CODE.SUCCESS) {
            if (res.data?.status === API_STATUS.SUCCESS) {
                return res.data.data.lenderProcessingFees;
            } else {
                const message = res.data?.message ?? GENERIC_ERROR;
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            }
        }
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
    return null;
};

export default {};
